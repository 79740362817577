import dayjs from 'dayjs'
import 'dayjs/locale/fr'

export const formatDate = (date: number) => dayjs(date).format('MMMM D, YYYY h:mm A')

export const makeInitials = (text = '') => {
  const match = text?.match(/(\b\S)?/g)

  return match ? match.join('') : text
}

export const guessGranularity = (firstDate: string | Date, secondDate: string | Date) => {
  if (!dayjs(firstDate).isValid() || !dayjs(secondDate).isValid()) {
    return ''
  }

  const diff = dayjs(secondDate).diff(firstDate, 'hour')

  if (diff >= 8760) {
    return 'year'
  } else if (diff >= 730) {
    return 'month'
  } else if (diff >= 168) {
    return 'week'
  } else {
    return 'day'
  }
}

export const formatXAxisDate = (date: string | Date | number, granularity: string) => {
  let format = ''

  switch (granularity) {
    case 'day':
      format = 'D. MMM'
      break
    case 'week':
      format = 'D. MMM'
      break
    case 'month':
      format = 'MMM YY'
      break
    default:
      format = 'D. MMM'
      break
  }

  return dayjs(date).isValid() ? dayjs(date).locale('fr').format(format) : date
}
