import { FC, useEffect } from 'react'
import { stagger, useAnimate } from 'framer-motion'

import { cn } from '@/utils'
import { buttonVariants } from '@/components/ui/button'
import { Icons } from '@/components/shared/icons'

import { HeroImage } from './hero-image'
import { Link } from 'react-router-dom'

const HeroSection: FC = () => {
  const [scope, animate] = useAnimate()

  useEffect(() => {
    animate(
      '#transform-anim',
      { opacity: [0, 1], y: [20, 0] },
      { duration: 0.5, ease: 'easeIn', delay: stagger(0.3) },
    )
  })

  return (
    <section className='mt-[10vh]'>
      <div ref={scope} className='relative flex flex-col items-center justify-center gap-y-24'>
        <div className='flex flex-col items-center justify-center'>
          <h1
            id='transform-anim'
            className='bg-heading-gradient bg-clip-text text-center font-heading  text-4xl tracking-tight text-transparent md:text-6xl md:leading-snug'
          >
            La prise de décision,{' '}
            <span className='bg-primary-gradient bg-clip-text text-transparent'>simplifiée</span>
          </h1>
          <p
            id='transform-anim'
            className='my-4 max-w-md text-center text-muted-foreground lg:max-w-2xl lg:text-lg 2xl:text-xl'
          >
            Datrics instaure la transparence et améliore votre visibilité sur toute l'activité de
            votre compagnie. Vous ne{' '}
            <span className='underline decoration-primary decoration-2 underline-offset-8'>
              perdrez plus de temps
            </span>{' '}
            à chercher une information.
          </p>

          <Link
            id='transform-anim'
            to='/auth/login'
            className={cn(
              buttonVariants({ variant: 'cta' }),
              'mt-8 flex h-12 items-center justify-center space-x-5 p-[.25rem_.3rem_.25rem_1.3rem] text-base',
            )}
          >
            <span>Commencer</span>
            <span className='right-0 inline-block rounded-full bg-white/50  p-[0.5rem]'>
              <Icons.chevronRight className='h-6 w-6 text-black/60' />
            </span>
          </Link>
        </div>
        <HeroImage />
      </div>
    </section>
  )
}

export default HeroSection
