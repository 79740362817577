import { marketingConfig } from '../config.ts'
import { cn } from '@/utils'
import { buttonVariants } from '@/components/ui/button'
import { MainNav } from './main-nav'
import Background from './background'
import SiteFooter from './site-footer'
import { TooltipProvider } from '@/components/ui/tooltip.tsx'
import { useUser } from '@/lib/auth.tsx'
import { Link } from 'react-router-dom'

interface MarketingLayoutProps {
  children: React.ReactNode
}

export default function MarketingLayout({ children }: MarketingLayoutProps) {
  const user = useUser()

  return (
    <TooltipProvider>
      <div className='flex min-h-screen flex-col'>
        <header className='sticky top-0 z-40 border-b bg-background/75 backdrop-blur-lg'>
          <div className='container flex h-20 items-center justify-between py-6'>
            <MainNav items={marketingConfig.mainNav} />
            <nav>
              <Link
                to={user?.data ? '/app' : '/auth/login'}
                className={cn(buttonVariants({ size: 'lg', variant: 'cta' }), 'rounded-full px-4')}
              >
                {user && user?.data ? 'Aller au tableau de bord' : 'Se connecter'}
              </Link>
            </nav>
          </div>
        </header>
        <main className='container flex-1'>{children}</main>
        <SiteFooter className='mt-[20vh]' />
        <Background />
      </div>
    </TooltipProvider>
  )
}
