import { Navigate, Outlet } from 'react-router-dom'

import { lazyImport } from '@/utils/lazyImport'
import { MainLayout } from '@/components/layout'
import { AuthLoader } from '@/lib/auth'
import { NotFound } from '@/features/misc'
import { AppError } from '@/features/misc/routes/app-error'

const { Dashboard } = lazyImport(() => import('@/features/misc'), 'Dashboard')
const { SalesRoutes } = lazyImport(() => import('@/features/sales'), 'SalesRoutes')
const { PoliciesRoutes } = lazyImport(() => import('@/features/policies'), 'PoliciesRoutes')
const { PurchasesRoutes } = lazyImport(() => import('@/features/purchases'), 'PurchasesRoutes')
const { RenewalsRoute } = lazyImport(() => import('@/features/renewals'), 'RenewalsRoute')
const { SettingsRoute } = lazyImport(() => import('@/features/settings'), 'SettingsRoute')

const App = () => {
  return (
    <AuthLoader
      renderLoading={() => null}
      renderUnauthenticated={() => <Navigate to='/auth/login' />}
    >
      <MainLayout>
        <Outlet />
      </MainLayout>
    </AuthLoader>
  )
}

export const protectedRoutes = [
  {
    path: 'app',
    element: <App />,
    handle: { crumb: () => 'Tableau de bord' },
    errorElement: <AppError />,
    children: [
      {
        path: 'sales/*',
        handle: { crumb: () => 'Editions' },
        element: <SalesRoutes />,
      },
      {
        path: 'contracts/*',
        element: <PoliciesRoutes />,
        handle: { crumb: () => 'Contrats' },
      },
      {
        path: 'purchases/*',
        element: <PurchasesRoutes />,
        handle: { crumb: () => 'Commandes' },
      },
      {
        path: 'renewals/*',
        element: <RenewalsRoute />,
        handle: { crumb: () => 'Renouvellements' },
      },
      { path: 'settings/*', element: <SettingsRoute /> },
      { path: '', element: <Dashboard /> },
      { path: '*', element: <NotFound /> },
    ],
  },
]
