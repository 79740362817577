import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { cn } from '@/utils'
import { Icons } from '@/components/shared/icons'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useLogin } from '@/lib/auth'
import { Link } from 'react-router-dom'

const formSchema = z.object({
  email: z.string().email({ message: 'Adresse e-mail invalide' }),
  // eslint-disable-next-line camelcase
  password: z.string({ required_error: 'Le mot de passe est requis' }),
  remember: z.boolean(),
})

type LoginFormProps = { className?: string; onSuccess: () => void; onError: (e: any) => void }

export function LoginForm({ className, onSuccess, onError, ...props }: LoginFormProps) {
  const login = useLogin()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    await login.mutate(values, { onSuccess, onError })
  }

  return (
    <>
      <div className={cn('flex flex-col items-start justify-start', className)} {...props}>
        <Link to='/'>
          <Icons.logo className='h-10 w-10' />
        </Link>
        <h2 className='mt-16 text-lg font-semibold text-gray-900'>
          Connectez-vous à votre compte.
        </h2>
        <p className='mt-2 text-sm text-gray-700'>
          Veuillez saisir vos identifiants ci-dessous pour accéder à votre espace.
        </p>
      </div>
      <div className='mt-10'>
        <div className='mt-6'>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='grid gap-4'>
              <FormField
                control={form.control}
                name='email'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Adresse e-mail</FormLabel>
                    <FormControl>
                      <Input placeholder='' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='password'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mot de passe</FormLabel>
                    <FormControl>
                      <Input required type='password' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button className='bg-amber-500' type='submit' disabled={login.isLoading}>
                {login.isLoading && <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />}
                Se connecter
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </>
  )
}
