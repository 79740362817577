const storagePrefix = 'datrics_'

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string)
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token))
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`)
  },
  getCubeToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}cubejs_token`) as string)
  },
  setCubeToken(token) {
    window.localStorage.setItem(`${storagePrefix}cubejs_token`, JSON.stringify(token))
  },
  clearCubeToken: () => {
    window.localStorage.removeItem(`${storagePrefix}cubejs_token`)
  },
}

export default storage
