import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PermissionProvider } from 'react-permission-role'

import { queryClient } from '@/lib/react-query'
import Loader from '@/components/shared/loader'
import { Toaster } from '@/components/ui/toaster'
import { TourProvider } from '@reactour/tour'
import { ThemeProvider } from '@/components/theme-provider'
import { AppError } from '@/features/misc/routes/app-error'

const LoaderScreen = () => {
  return (
    <div className='z-50 flex h-screen w-screen items-center justify-center'>
      <Loader />
    </div>
  )
}

type AppProviderProps = {
  children: React.ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ThemeProvider defaultTheme='light'>
      <ErrorBoundary fallback={<AppError />}>
        <React.Suspense fallback={<LoaderScreen />}>
          <PermissionProvider>
            <TourProvider steps={[]}>
              <HelmetProvider>
                <QueryClientProvider client={queryClient}>
                  {import.meta.env.NODE_ENV === 'DEV' && <ReactQueryDevtools />}
                  {children}
                  <Toaster />
                </QueryClientProvider>
              </HelmetProvider>
            </TourProvider>
          </PermissionProvider>
        </React.Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
