import * as React from 'react'

import { Nav } from '@/components/template/nav'
import { axios } from '@/lib/axios'
import storage from '@/utils/storage'
import cubejs from '@cubejs-client/core'
import { CubeProvider } from '@cubejs-client/react'
import { CUBEJS_API_URL } from '@/config'
import { usePermission } from 'react-permission-role'
import { useUser } from '@/lib/auth'

type MainLayoutProps = {
  children: React.ReactNode
}

const cubejsApi = cubejs(
  async () => {
    if (!storage.getCubeToken()) {
      try {
        const { data } = await axios.get('/api/v1/auth/cubejs-token')
        storage.setCubeToken(data.token)
      } catch (e) {
        console.log('cubejsApi', e)
      }
    }

    return storage.getCubeToken()
  },
  {
    apiUrl: CUBEJS_API_URL,
  },
)

export const MainLayout = ({ children }: MainLayoutProps) => {
  const user = useUser()
  const { setUser } = usePermission()

  setUser({
    id: user.data?.id,
    roles: user.data?.roles.map((role) => role.name),
    permissions: user.data?.roles[0].permissions.map((permission) => permission.name),
  })

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <div className='flex h-screen flex-col overflow-hidden bg-white dark:bg-zinc-950'>
        <Nav />
        <main className='relative flex-1 overflow-y-auto focus:outline-none'>{children}</main>
      </div>
    </CubeProvider>
  )
}
