import FeaturesSection from '../components/features/features-section'
import HeroSection from '../components/hero/hero-section'
import CompanySection from '../components/company/company'
import PricingSection from '../components/pricing/pricing-section'
// import TestimonialsSection from '../components/testimonials/testomonials-section'
import MarketingLayout from '../components/layout'

export const LandingPage = () => {
  return (
    <MarketingLayout>
      <HeroSection />
      <FeaturesSection />
      {/* <TestimonialsSection /> */}
      <PricingSection />
      <CompanySection />
    </MarketingLayout>
  )
}
