import { FC } from 'react'

import { siteConfig } from '../config.ts'
import { cn } from '@/utils'

import { Icons } from '@/components/shared/icons'

interface SiteFooterProps extends React.HTMLAttributes<HTMLElement> {
  className?: string
}

const SiteFooter: FC<SiteFooterProps> = ({ className }) => {
  return (
    <footer className={cn('min-h-[30vh] w-full border-t bg-zinc-800 py-10 text-muted', className)}>
      <div className='container space-y-20 '>
        <div className='space-y-8 xl:col-span-2'>
          <div className='space-y-10'>
            <div className='flex items-center gap-x-2'>
              <Icons.logo className='h-8 w-8 ' />
              <h3 className='text-md text-muted lg:text-lg'>
                Datrics - Plateforme de visualisation et d'analyse de données
              </h3>
            </div>
            <div className='flex items-center gap-x-2'>
              <a
                href={siteConfig.links.github}
                target='_blank'
                rel='noreferrer'
                className='rounded-md p-2 transition-colors hover:bg-foreground/20 active:bg-muted/30'
              >
                <span className='sr-only'>Github</span>
                <Icons.github className='h-5 w-5' />
              </a>
              <div className='h-8 border-l border-muted-foreground/50' />

              <a
                href={siteConfig.links.twitter}
                target='_blank'
                rel='noreferrer'
                className='rounded-md p-2 transition-colors hover:bg-foreground/20 active:bg-foreground/30'
              >
                <span className='sr-only'>Twitter</span>
                <Icons.twitter className='h-5 w-5' />
              </a>
              <div className='h-8 border-l border-muted-foreground/50' />

              <a
                href={siteConfig.links.linkedin}
                target='_blank'
                rel='noreferrer'
                className='rounded-md p-2 transition-colors hover:bg-foreground/20 active:bg-foreground/30'
              >
                <span className='sr-only'>Linkedin</span>
                <Icons.linkedIn className='h-5 w-5' />
              </a>
            </div>
          </div>
          <div className='mt-16 grid grid-cols-2 gap-8 xl:col-span-3 xl:mt-0'>
            <div className='md:grid md:grid-cols-2 md:gap-8'>
              <div>
                <h3>Produit</h3>
                <ul role='list' className='mt-4 space-y-4'>
                  <li>
                    <a
                      className='text-sm text-muted transition-colors duration-200 ease-in-out hover:text-accent-foreground'
                      href='#features'
                    >
                      Fonctionnalités
                    </a>
                  </li>
                  <li>
                    <a
                      className='text-sm text-muted transition-colors duration-200 ease-in-out hover:text-accent-foreground'
                      href='#pricing'
                    >
                      Facturation
                    </a>
                  </li>
                </ul>
              </div>
              <div className='mt-10 md:mt-0'>
                <h3>Compagnie</h3>
                <ul role='list' className='mt-4 space-y-4'>
                  <li>
                    <a
                      className='text-sm text-muted transition-colors duration-200 ease-in-out hover:text-accent-foreground'
                      href='#pricing'
                    >
                      A propos de nous
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='md:grid md:grid-cols-2 md:gap-8'>
              <div>
                <h3>Utilisation</h3>
                <ul role='list' className='mt-4 space-y-4'>
                  <li>
                    <a
                      className='text-sm text-muted transition-colors duration-200 ease-in-out hover:text-accent-foreground'
                      href='#'
                    >
                      Documentation
                    </a>
                  </li>
                </ul>
              </div>
              <div className='mt-10 md:mt-0'>
                <h3>Légal</h3>
                <ul role='list' className='mt-4 space-y-4'>
                  <li>
                    <a
                      className='text-sm text-muted transition-colors duration-200 ease-in-out hover:text-accent-foreground'
                      href='#'
                    >
                      Politique de confidentialité
                    </a>
                  </li>
                  <li>
                    <a
                      className='text-sm text-muted transition-colors duration-200 ease-in-out hover:text-accent-foreground'
                      href='#'
                    >
                      Termes et conditions d'utilisation
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-16 border-t  pt-8 sm:mt-20 lg:mt-24'>
          <p className='text-sm leading-5 text-muted'>
            &copy; {new Date().getFullYear()} datrics.asacitechnologies.com
          </p>
        </div>
      </div>
    </footer>
  )
}

export default SiteFooter
