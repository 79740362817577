import React from 'react'
import { Search } from './search'
import { UserDropdown } from './user-dropdown'
import Logo from './logo'
import { Link, useNavigate } from 'react-router-dom'
import { Icons } from '../shared/icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import CompanyCard from './company-card'

type NavigationItem = {
  name: string
  to: string
  icon: React.ReactNode
}

const navigation = [
  { name: '💸 Editions', to: 'sales', icon: <Icons.folder size={16} className='mr-2' /> },
  { name: '📈 Commandes', to: 'purchases', icon: <Icons.folder size={16} className='mr-2' /> },
  { name: '📣 Contrats', to: 'contracts', icon: <Icons.folder size={16} className='mr-2' /> },
  { name: '🤝 Renouvellements', to: 'renewals', icon: <Icons.folder size={16} className='mr-2' /> },
].filter(Boolean) as NavigationItem[]

export const Nav = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className='hidden flex-col bg-zinc-900 text-secondary-foreground md:flex'>
        <div className=''>
          <div className='flex h-12 items-center px-4'>
            <Link to='.'>
              <Logo imgClass='h-10' />
            </Link>
            <div className='mx-6 inline-flex rounded'>
              <button
                type='button'
                className='-ml-px inline-flex items-center justify-center gap-2 border border-gray-700 bg-zinc-800 px-4 py-1 align-middle text-sm font-medium text-white transition-all first:ml-0 first:rounded-l-lg last:rounded-r-lg hover:bg-zinc-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-slate-800'
              >
                <Icons.add size={18} />
                Nouveau
              </button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <button
                    type='button'
                    className='-ml-px inline-flex items-center justify-center gap-2 border border-gray-700 bg-zinc-800 px-4 py-1 align-middle text-sm font-medium text-white transition-all first:ml-0 first:rounded-l-lg last:rounded-r-lg hover:bg-zinc-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-slate-800'
                  >
                    <Icons.dashboard size='18' />
                    Parcourir
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent sideOffset={5}>
                  <DropdownMenuItem>
                    <Icons.folders size={16} className='mr-2' /> Toutes les thématiques
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Icons.dashboard size={16} className='mr-2' /> Tous les dashboards
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Icons.barChart size={16} className='mr-2' /> Toutes les cartes
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuLabel>Thématiques</DropdownMenuLabel>
                  {navigation.map((item, index) => (
                    <DropdownMenuItem
                      key={index}
                      onClick={() => navigate(item.to)}
                      className='cursor-pointer'
                    >
                      <Icons.folder size={16} className='mr-2' /> {item.name}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <Search />
            <div className='ml-auto flex items-center space-x-4'>
              <div className='mx-6 inline-flex rounded'>
                <button
                  type='button'
                  className='-ml-px inline-flex items-center justify-center gap-2 border border-gray-700 bg-zinc-800 px-4 py-1 align-middle text-sm font-medium text-white transition-all first:ml-0 first:rounded-l-lg last:rounded-r-lg hover:bg-zinc-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-slate-800'
                >
                  <Icons.settings size={18} />
                </button>
                <button
                  type='button'
                  className='-ml-px inline-flex items-center justify-center gap-2 border border-gray-700 bg-zinc-800 px-4 py-1 align-middle text-sm font-medium text-white transition-all first:ml-0 first:rounded-l-lg last:rounded-r-lg hover:bg-zinc-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-slate-800'
                >
                  <Icons.bell size={18} />
                </button>
                <button
                  type='button'
                  className='-ml-px inline-flex items-center justify-center gap-2 border border-gray-700 bg-zinc-800 px-4 py-1 align-middle text-sm font-medium text-white transition-all first:ml-0 first:rounded-l-lg last:rounded-r-lg hover:bg-zinc-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-slate-800'
                >
                  <Icons.help size={18} />
                </button>
                <button
                  type='button'
                  className='-ml-px inline-flex items-center justify-center gap-2 border border-gray-700 bg-zinc-800 px-4 py-1 align-middle text-sm font-medium text-white transition-all first:ml-0 first:rounded-l-lg last:rounded-r-lg hover:bg-zinc-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-slate-800'
                >
                  <Icons.sparkles size='18' />
                </button>
              </div>
              <CompanyCard />
              <UserDropdown />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
