import { FC, useEffect } from 'react'
import { stagger, useAnimate, useInView } from 'framer-motion'

import { cn } from '@/utils'
import { buttonVariants } from '@/components/ui/button'
import { Icons } from '@/components/shared/icons'
import { Link } from 'react-router-dom'

interface PricingProps {}

const Pricing: FC<PricingProps> = () => {
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { once: true })

  useEffect(() => {
    if (isInView) {
      animate(
        '#reveal-anim',
        { opacity: [0, 1], y: [15, 0] },
        { duration: 0.5, ease: 'easeIn', delay: stagger(0.3) },
      )
    }
  }, [animate, isInView])
  return (
    <section className='container flex flex-col  gap-6 py-8 md:max-w-[64rem] md:py-12 lg:py-24'>
      <div className='grid w-full items-start gap-10 rounded-lg border p-10 md:grid-cols-[1fr_300px]'>
        <div className='grid gap-6'>
          <h3 className='text-xl font-bold sm:text-2xl'>Ce qui est inclus dans l'abonnement</h3>
          <ul className='grid gap-3 text-sm text-muted-foreground sm:grid-cols-2'>
            <li className='flex items-center'>
              <Icons.check className='mr-2 h-4 w-4' /> Tableaux de bords illimités
            </li>
            <li className='flex items-center'>
              <Icons.check className='mr-2 h-4 w-4' /> Nombre d'utilisateurs illimités
            </li>
            <li className='flex items-center'>
              <Icons.check className='mr-2 h-4 w-4' /> Interface personnalisé
            </li>
            <li className='flex items-center'>
              <Icons.check className='mr-2 h-4 w-4' /> Dashboard personnalisés
            </li>
            <li className='flex items-center'>
              <Icons.check className='mr-2 h-4 w-4' /> Partage des visualisations
            </li>
            <li className='flex items-center'>
              <Icons.check className='mr-2 h-4 w-4' /> Support Premium
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-4 text-center'>
          <div>
            <h4 className='text-3xl font-bold'>5 000 000 XOF</h4>
            <p className='text-sm font-medium text-muted-foreground'>Facturé une fois</p>
          </div>
          <Link to='/auth/login' className={cn(buttonVariants({ size: 'lg' }))}>
            Commencer
          </Link>
        </div>
      </div>
      <div className='mx-auto flex w-full max-w-[58rem] flex-col gap-4'>
        <p className='max-w-[85%] leading-normal text-muted-foreground sm:leading-7'>
          Le coût d'utilisation annuel est de <strong>1 000 000 XOF.</strong>
        </p>
      </div>
    </section>
    // <div ref={scope} className='grid grid-cols-1 gap-10 py-16 md:grid-cols-2'>
    //   {pricingItems.map((item) => (
    //     <Card key={item.id} id='reveal-anim'>
    //       <CardHeader>
    //         <CardTitle className='text-2xl font-bold md:text-3xl lg:text-4xl'>
    //           {item.plan}
    //         </CardTitle>
    //         <CardDescription>{item.tagline}</CardDescription>
    //       </CardHeader>
    //       <CardContent className='space-y-8'>
    //         <h3 className='lg:text-5x text-2xl font-bold md:text-3xl'>
    //           {item.price}{' '}
    //           <span className='inline-block align-baseline text-base font-normal text-muted-foreground'>
    //             {item.period}{' '}
    //             <span className='ml-2 inline-block rounded-lg border p-1 text-base'>
    //               {item.plan}
    //             </span>
    //           </span>
    //         </h3>
    //         <a
    //           className={cn(
    //             buttonVariants({ size: 'lg' }),
    //             'bg-gradient-to-tr from-blue-600 to-cyan-600 text-white',
    //           )}
    //           href='/journal/billing'
    //         >
    //           Get Started
    //         </a>
    //         <div className='space-y-5'>
    //           <p className='text-lg'>Includes</p>

    //           {item.features.map((feature) => (
    //             <div key={feature.id} className='flex items-center gap-x-5'>
    //               {!feature.negative ? (
    //                 <Icons.check className='text-green-500' />
    //               ) : (
    //                 <Icons.close className='text-gray-500' />
    //               )}
    //               <div className='flex items-center gap-x-2'>
    //                 <p
    //                   className={cn(!feature.negative ? 'text-muted-foreground' : 'text-gray-500')}
    //                 >
    //                   {feature.text}
    //                 </p>
    //                 {feature.footnote && (
    //                   <Tooltip>
    //                     <TooltipTrigger asChild>
    //                       <Icons.help className='h-4 w-4 text-muted-foreground' />
    //                     </TooltipTrigger>
    //                     <TooltipContent className='max-w-xs bg-muted p-5 text-center'>
    //                       <p className='text-foreground'>{feature.footnote}</p>
    //                     </TooltipContent>
    //                   </Tooltip>
    //                 )}
    //               </div>
    //             </div>
    //           ))}
    //         </div>
    //       </CardContent>
    //     </Card>
    //   ))}
    // </div>
  )
}

export default Pricing
