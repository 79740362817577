import { FC, useEffect } from 'react'
import { stagger, useAnimate, useInView } from 'framer-motion'

import { Card, CardContent } from '@/components/ui/card'

interface FeaturesProps {}

const features = [
  {
    id: 'sales',
    title: `Editions`,
    description: `Des indicateurs sur vos performances d'éditions et celles de votre réseau.`,
    thumbnail: '/img/features/demo-sales-thumbnail.png',
    demo: '/img/features/demo-sales.mp4',
  },
  {
    id: 'purchases',
    title: `Commandes`,
    description: `Une vue détaillée sur vos commandes d'attestations.`,
    thumbnail: '/img/features/demo-purchases-thumbnail.png',
    demo: '/img/features/demo-purchases.mp4',
  },
  {
    id: 'contrats',
    title: `Contrats`,
    description: `Des indicateurs sur les types de contrats dans votre portefeuille.`,
    thumbnail: '/img/features/demo-policies-thumbnail.png',
    demo: '/img/features/demo-policies.mp4',
  },
  {
    id: 'renewals',
    title: `Renouvellements`,
    description: `Des indicateurs sur vos performances commerciales.`,
    thumbnail: '/img/features/demo-renewals-thumbnail.png',
    demo: '/img/features/demo-renewals.mp4',
  },
]

const Features: FC<FeaturesProps> = () => {
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { once: true })

  useEffect(() => {
    if (isInView) {
      animate(
        '#reveal-anim',
        { opacity: [0, 1], y: [25, 0] },
        { duration: 0.5, ease: 'easeIn', delay: stagger(0.3) },
      )
    }
  }, [animate, isInView])
  return (
    <div ref={scope} className='grid grid-cols-1 gap-10 py-16 md:grid-cols-2'>
      {features.map((feature) => (
        <Card className='overflow-hidden' key={feature.id} id='reveal-anim'>
          <CardContent className='space-y-10 p-0'>
            <div className='space-y-5 px-6 py-8'>
              <h3 className='text-center font-heading text-2xl  leading-normal tracking-tight text-foreground lg:text-3xl'>
                {feature.title}
              </h3>
              <p className='text-center text-muted-foreground lg:text-base'>
                {feature.description}
              </p>
            </div>
            <div className='relative '>
              <div className='absolute inset-0 -top-1 left-9 z-0 rounded-md bg-primary-gradient' />
              <video
                autoPlay
                loop
                muted
                width={800}
                height={600}
                poster={feature.thumbnail}
                className='relative z-10 ml-10 rounded-md object-cover'
              >
                <source src={feature.demo} type='video/mp4' />
                Votre navigateur ne supporte pas les balises vidéos.
              </video>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  )
}

export default Features
