import * as React from 'react'
import { MainNavItem } from '../types'

import { siteConfig } from '../config.ts'
import { cn } from '@/utils'
import { Icons } from '@/components/shared/icons'
import { MobileNav } from './mobile-nav'

interface MainNavProps {
  items?: MainNavItem[]
  children?: React.ReactNode
}

export function MainNav({ items, children }: MainNavProps) {
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false)

  return (
    <div className='flex  gap-6 md:gap-10'>
      <a href='/' className='hidden items-center space-x-2 md:flex'>
        <Icons.logo className='h-10 w-10 ' />
        <span className=' font-bold sm:inline-block'>{siteConfig.name}</span>
      </a>

      <nav className='hidden gap-6 md:flex'>
        {items?.map((item, index) => (
          <a
            key={index}
            href={item.disabled ? '#' : item.href}
            className={cn(
              'flex items-center text-lg font-medium transition-colors hover:text-foreground/80 sm:text-sm',
              item.href.startsWith(`/app`) ? 'text-foreground' : 'text-foreground/60',
              item.disabled && 'cursor-not-allowed opacity-80',
            )}
          >
            {item.title}
          </a>
        ))}
      </nav>

      <button className='md:hidden' onClick={() => setShowMobileMenu(!showMobileMenu)}>
        {showMobileMenu ? <Icons.close /> : <Icons.menu />}
      </button>
      {showMobileMenu && items && <MobileNav items={items}>{children}</MobileNav>}
    </div>
  )
}
