import { Icons } from '@/components/shared/icons'
import { Button } from '@/components/ui'

export const NotFound = () => {
  return (
    <section className='bg-white dark:bg-gray-900 '>
      <div className='container mx-auto flex min-h-screen items-center px-6 py-12'>
        <div className='mx-auto flex max-w-sm flex-col items-center text-center'>
          <p className='rounded-full bg-yellow-50 p-3 text-sm font-medium text-yellow-500 dark:bg-gray-800'>
            <Icons.warning />
          </p>
          <h1 className='mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl'>
            Page non trouvé
          </h1>
          <p className='mt-4 text-gray-500 dark:text-gray-400'>
            La page que vous recherchez n'existe pas. Voici quelques liens utiles :
          </p>

          <div className='mt-6 flex w-full shrink-0 items-center gap-x-3 sm:w-auto'>
            <Button variant='outline'>
              <Icons.moveLeft className='mr-2 h-6 w-6' />
              Retour
            </Button>
            <Button>Aller à la page d'accueil</Button>
          </div>
        </div>
      </div>
    </section>
  )
}
