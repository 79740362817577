import { Icons } from '@/components/shared/icons'
import { Button } from '@/components/ui'

export const AppError = () => {
  const reload = () => {
    window.location.reload()
  }

  return (
    <section className='bg-white dark:bg-gray-900 '>
      <div className='container mx-auto flex min-h-screen items-center px-6 py-12'>
        <div className='mx-auto flex max-w-sm flex-col items-center text-center'>
          <p className='rounded-full bg-red-50 p-3 text-sm font-medium text-red-500 dark:bg-gray-800'>
            <Icons.error />
          </p>
          <h1 className='mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl'>
            Oups, quelque chose s'est mal passé.
          </h1>
          <p className='mt-4 text-gray-500 dark:text-gray-400'>
            Essayez d'actualiser cette page ou n'hésitez pas à nous contacter si le problème
            persiste.
          </p>
          <div className='mt-6 flex w-full shrink-0 items-center gap-x-3 sm:w-auto'>
            <Button variant='ghost'>
              <Icons.moveLeft className='mr-2 h-4 w-4' />
              Retour
            </Button>
            <Button onClick={reload} variant='secondary'>
              Actualiser
              <Icons.refresh className='ml-2 h-4 w-4' />
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
