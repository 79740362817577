import { axios } from '@/lib/axios'
import { UserResponse } from '../types'

export type LoginCredentialsDTO = {
  email: string
  password: string
  remember: boolean
}

export const loginWithEmailAndPassword = async (
  data: LoginCredentialsDTO,
): Promise<UserResponse> => {
  await axios.get('sanctum/csrf-cookie')
  return axios.post('/login', data)
}
