import Axios, { InternalAxiosRequestConfig } from 'axios'

import { API_URL } from '@/config'
import { toast } from '@/components/ui/use-toast'
import storage from '@/utils/storage'

export const axios = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

axios.interceptors.request.use(authRequestInterceptor)

axios.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    console.log(error)
    const message = error.response?.data?.message || error.message

    if (error.response.status === 401) {
      storage.clearCubeToken()
    } else {
      toast({
        title: 'Une erreur est survenue',
        description: message,
        variant: 'destructive',
      })
    }

    return Promise.reject(error)
  },
)

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  // config.headers.Accept = 'application/json'
  // config.headers['X-Requested-With'] = 'XMLHttpRequest'

  return config
}
