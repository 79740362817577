import { APP_NAME } from '@/config'
import logoDarkFull from '@/assets/img/logo/logo-dark-full.png'
import logoDarkStreamline from '@/assets/img/logo/logo-dark-streamline.png'
import logoLightFull from '@/assets/img/logo/logo-light-full.png'
import logoLightStreamline from '@/assets/img/logo/logo-light-streamline.png'
import { cn } from '@/utils'

const logos = new Map<string, string>([
  ['logo-dark-full', logoDarkFull],
  ['logo-dark-streamline', logoDarkStreamline],
  ['logo-light-full', logoLightFull],
  ['logo-light-streamline', logoLightStreamline],
])

interface Props {
  mode?: 'light' | 'dark'
  type?: 'full' | 'streamline'
  gutter?: string
  imgClass?: string
  logoWidth?: string | number
  className?: string
  style?: any
}

const Logo = (props: Props) => {
  const {
    type = 'streamline',
    mode = 'light',
    gutter,
    className,
    imgClass,
    style,
    logoWidth = 'auto',
  } = props

  return (
    <div
      className={cn('logo', className, gutter)}
      style={{
        ...style,
        ...{ width: logoWidth },
      }}
    >
      <img
        className={imgClass}
        src={`${logos.get('logo-' + mode + '-' + type)}`}
        alt={`${APP_NAME} logo`}
      />
    </div>
  )
}

export default Logo
