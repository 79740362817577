import { cn } from '@/utils'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { useUser } from '@/lib/auth'
import { makeInitials } from '@/utils/format'

interface TeamSwitcherProps {
  className?: string
}

export default function CompanyCard({ className }: TeamSwitcherProps) {
  const user = useUser()

  return (
    <Button variant='ghost' className={cn('h-7 border border-muted-foreground', className)}>
      <Avatar className='mr-2 h-5 w-5'>
        <AvatarImage
          src={`https://avatar.vercel.sh/${user.data?.organization.id}.png`}
          alt={user.data?.organization.name}
          className='grayscale'
        />
        <AvatarFallback>{makeInitials(user.data?.organization.name)}</AvatarFallback>
      </Avatar>
      {user.data?.organization.name}
    </Button>
  )
}
