import {
  type Icon as LucideIcon,
  BellIcon,
  PlusCircle,
  Sparkles,
  AlertTriangle,
  ArrowRight,
  Check,
  ChevronLeft,
  ChevronRight,
  HelpCircle,
  Laptop,
  Loader2,
  LucideProps,
  Settings,
  X,
  Archive,
  Disc2,
  MoveRight,
  LayoutDashboard,
  Download,
  Folder,
  FoldersIcon,
  BarChart3,
  MenuIcon,
  ShoppingCart,
  DollarSign,
  ChevronsLeft,
  ChevronsRight,
  Github,
  Twitter,
  Linkedin,
  QuoteIcon,
  User,
  PaintBucket,
  Link,
  Users2,
  CheckCircle,
  Percent,
  MoveLeft,
  Ban,
  UserPlus,
  Files,
  LogOut,
  Bug,
  RefreshCcw,
} from 'lucide-react'

export type Icon = LucideIcon

export const Icons = {
  logo: (props: LucideProps) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' {...props}>
      <rect width='256' height='256' fill='none' />
      <line
        x1='208'
        y1='128'
        x2='128'
        y2='208'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='16'
      />
      <line
        x1='192'
        y1='40'
        x2='40'
        y2='192'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='16'
      />
    </svg>
  ),
  archive: Archive,
  disc: Disc2,
  dashboard: LayoutDashboard,
  download: Download,
  menu: MenuIcon,
  close: X,
  right: MoveRight,
  bell: BellIcon,
  sparkles: Sparkles,
  folder: Folder,
  files: Files,
  shoppingCart: ShoppingCart,
  dollar: DollarSign,
  folders: FoldersIcon,
  barChart: BarChart3,
  moveRight: MoveRight,
  moveLeft: MoveLeft,
  spinner: Loader2,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronDoubleLeft: ChevronsLeft,
  chevronDoubleRight: ChevronsRight,
  settings: Settings,
  add: PlusCircle,
  warning: AlertTriangle,
  error: Bug,
  arrowRight: ArrowRight,
  help: HelpCircle,
  check: Check,
  laptop: Laptop,
  quote: QuoteIcon,
  github: Github,
  twitter: Twitter,
  linkedIn: Linkedin,
  user: User,
  userPlus: UserPlus,
  users: Users2,
  refresh: RefreshCcw,
  paintBucket: PaintBucket,
  link: Link,
  checkCircle: CheckCircle,
  percentage: Percent,
  ban: Ban,
  logout: LogOut,
}
