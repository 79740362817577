import { SiteConfig, MarketingConfig } from './types'

export const siteConfig: SiteConfig = {
  name: 'Datrics',
  description:
    "Plateforme permettant aux compagnies de suivre l'évolution de leurs activités grâce à l'exposition de plusieurs indicateurs et données.",
  url: 'https://datrics.asacitech.com',
  ogImage: 'https://datrics.asacitech.com/og.jpg',
  links: {
    twitter: 'https://twitter.com/asacitechnologies',
    github: 'https://github.com/asacitechnologies',
    linkedin: 'https://www.linkedin.com/in/asacitechnologies/',
  },
}

export const marketingConfig: MarketingConfig = {
  mainNav: [
    {
      title: 'Fonctionnalités',
      href: '#features',
    },
    {
      title: 'Facturation',
      href: '#pricing',
    },
  ],
}
