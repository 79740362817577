import { configureAuth } from 'react-query-auth'

import {
  loginWithEmailAndPassword,
  logout,
  getCurrentUser,
  LoginCredentialsDTO,
  AuthUser,
} from '@/features/auth'

import storage from '@/utils/storage'

async function userFn() {
  try {
    const { data } = await getCurrentUser()
    return data
  } catch (error) {
    return null
  }
}

async function handleLoginResponse(loginResponse: { two_factor: boolean }) {
  if (loginResponse.two_factor) {
    // TODO: Start 2FA Flow
  }
}

async function loginFn(credentials: LoginCredentialsDTO) {
  const data = await loginWithEmailAndPassword(credentials)
  await handleLoginResponse(data)
}

async function logoutFn() {
  storage.clearCubeToken()
  await logout()
}

const authConfig = {
  userFn,
  loginFn,
  logoutFn,
}

export const { useUser, useLogin, useLogout, AuthLoader } = configureAuth<
  AuthUser | null,
  LoginCredentialsDTO
>(authConfig)
