import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { protectedRoutes } from './protected'
import { publicRoutes } from './public'
import { LandingPage } from '@/features/marketing'
import { NotFound } from '@/features/misc'

export const AppRoutes = () => {
  const commonRoutes = [
    { path: '/', element: <LandingPage /> },
    { path: '*', element: <NotFound /> },
  ]

  const router = createBrowserRouter([...protectedRoutes, ...publicRoutes, ...commonRoutes])

  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}
