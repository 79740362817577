import { FC, useEffect } from 'react'
import { stagger, useAnimate, useInView } from 'framer-motion'

import { cn } from '@/utils'
import { buttonVariants } from '@/components/ui/button'
import { Icons } from '@/components/shared/icons'

const CompanySection: FC = () => {
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { once: true })

  useEffect(() => {
    if (isInView) {
      animate(
        '#reveal-anim',
        { opacity: [0, 1], y: [20, 0] },
        { duration: 0.5, ease: 'easeIn', delay: stagger(0.3) },
      )
    }
  }, [animate, isInView])
  return (
    <section ref={scope} className='relative mt-[10vh] min-h-[30vh]'>
      <div className='flex flex-col items-center justify-center space-y-8 py-10'>
        <h2
          id='reveal-anim'
          className='bg-heading-gradient bg-clip-text  text-center font-heading  text-3xl tracking-tight text-transparent md:text-5xl md:leading-snug'
        >
          Développé avec ❤️ par ASACI TECHNOLOGIES.
        </h2>
        <a
          id='reveal-anim'
          href='https://asacitechnologies.com'
          className={cn(
            buttonVariants({ size: 'lg' }),
            'text- cursor-pointer gap-x-3 bg-secondary-gradient-4 text-white transition-shadow duration-300  hover:shadow-[0px_4px_30px] hover:shadow-[rgb(55_65_81_/_50%)]',
          )}
          target='_blank'
          rel='noreferrer'
        >
          <Icons.link className='h-5 w-5 text-background' /> Visiter le site
        </a>
      </div>
    </section>
  )
}

export default CompanySection
